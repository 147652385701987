import React, { useState, useEffect } from 'react';
import './App.css';
import backgroundImage from './pic.jpg';
import slide1 from './pic1.jpg';
import slide2 from './pic2.jpg';
import slide3 from './pic3.jpg';
import logo from './logo.jpg';
import translations from './translations';
import languageIcon from './language-icon.svg'; // Import the language icon

function App() {
  const [popupText, setPopupText] = useState('');
  const [currentLanguage, setCurrentLanguage] = useState('English');
  const [languages] = useState([
    'English', 'Deutsch', 'العربية', 'Bulgarsk', '简体中文', 'DANSK', 'ESTNISH',
    'FINNISH', 'FRANÇAIS', 'ΕΛΛΗΝΙΚΑ', 'INDONESIA', 'ITALIANO', '日本語', 'LETTISCH', 
    'LIETUVIŲ', 'NEDERLAND', 'Bokmål', 'POLSKI', 'PORTUGUÊS', 'PORTUGUÊS_BR', 
    'ROMAN', 'РУССКИЙ', 'SVENSK', 'SLOVENSKÉ', 'SLOWENIC', 'ESPAÑOL', 'ČESKY', 
    'TÜRKÇE', 'УКРАЇНСЬКА', 'MAGYARORSZÁG'
  ]);
  const [isLanguagePopupOpen, setIsLanguagePopupOpen] = useState(false);

  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    const languageMapping = {
      'en': 'English',
      'de': 'Deutsch',
      'ar': 'العربية',
      'bg': 'Bulgarsk',
      'zh': '简体中文',
      'da': 'DANSK',
      'et': 'ESTNISH',
      'fi': 'FINNISH',
      'fr': 'FRANÇAIS',
      'el': 'ΕΛΛΗΝΙΚΑ',
      'id': 'INDONESIA',
      'it': 'ITALIANO',
      'ja': '日本語',
      'lv': 'LETTISCH',
      'lt': 'LIETUVIŲ',
      'nl': 'NEDERLAND',
      'no': 'Bokmål',
      'pl': 'POLSKI',
      'pt': 'PORTUGUÊS',
      'pt-BR': 'PORTUGUÊS_BR',
      'ro': 'ROMAN',
      'ru': 'РУССКИЙ',
      'sv': 'SVENSK',
      'sk': 'SLOVENSKÉ',
      'sl': 'SLOWENIC',
      'es': 'ESPAÑOL',
      'cs': 'ČESKY',
      'tr': 'TÜRKÇE',
      'uk': 'УКРАЇНСЬКА',
      'hu': 'MAGYARORSZÁG'
    };

    const defaultLanguage = languageMapping[browserLanguage.split('-')[0]] || 'English';
    setCurrentLanguage(defaultLanguage);
  }, [languages]);

  const handlePopup = (text) => {
    setPopupText(text);
    document.body.classList.add('no-scroll');
  };

  const closePopup = () => {
    setPopupText('');
    document.body.classList.remove('no-scroll');
  };

  const handleOverlayClick = (e) => {
    if (e.target.className === 'text-popup-overlay') {
      closePopup();
    }
  };

  const handleExploreProducts = () => {
    window.location.href = 'https://voltcola.de/Produkte/';
  };

  const changeLanguage = (language) => {
    setCurrentLanguage(language);
    setIsLanguagePopupOpen(false); // Close the popup when a language is selected
  };

  const getTranslation = (key) => {
    const lang = currentLanguage;
    if (key === 'impressum' || key === 'impressum_name') {
      if (!['English', 'Deutsch', 'FRANÇAIS', 'ESPAÑOL'].includes(lang)) {
        return translations['English'][key];
      }
    } else if (key === 'dataprivacy_name' || key === 'datenschutz') {
      if (!['English', 'Deutsch'].includes(lang)) {
        return translations['English'][key];
      }
    }
    return translations[lang][key];
  };

  return (
    <div className="App">
      <div className="top-bar">
        <img src={logo} alt="Logo" className="logo" />
        <div className="language-selector">
          <button className="language-button" onClick={() => setIsLanguagePopupOpen(true)}>
            <img src={languageIcon} alt="Language Icon" className="language-icon" /> {/* Add the language icon */}
            <span className="language-text">{currentLanguage}</span>
            <span className="arrow-down">▼</span>
          </button>
        </div>
      </div>
      <div className="image-container">
        <img src={backgroundImage} alt="Background" className="background-image" />
      </div>
      <div className="image-text-wrapper">
        <div className="image-column">
          <img src={slide1} alt="Slide 1" />
          <img src={slide2} alt="Slide 2" />
          <img src={slide3} alt="Slide 3" />
        </div>
        <div className="text-container">
          <p>{translations[currentLanguage].intro}</p>
          <p>{translations[currentLanguage].point1}</p>
          <p>{translations[currentLanguage].point2}</p>
          <p>{translations[currentLanguage].point3}</p>
          <p>{translations[currentLanguage].caution}</p>
        </div>
      </div>
      <div className="footer">
        <span className="footer-link" onClick={() => handlePopup(getTranslation('impressum'))}>
          {getTranslation('impressum_name')}
        </span>
        <span className="footer-link" onClick={() => handlePopup(getTranslation('datenschutz'))}>
          {getTranslation('dataprivacy_name')}
        </span>
      </div>
      {popupText && (
        <div className="text-popup-overlay" onClick={handleOverlayClick}>
          <div className="text-popup">
            <button className="close-button" onClick={closePopup}>X</button>
            {popupText}
          </div>
        </div>
      )}
      <button className="explore-button" onClick={handleExploreProducts}>
        {translations[currentLanguage].explore}
      </button>
      {isLanguagePopupOpen && (
        <div className="language-popup-overlay" onClick={() => setIsLanguagePopupOpen(false)}>
          <div className="language-popup" onClick={(e) => e.stopPropagation()}>
            {languages.map((language, index) => (
              <div key={index} onClick={() => changeLanguage(language)}>
                {language}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
