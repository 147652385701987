const translations = {
  English: {
    intro: "Volt Power Cola gives you the ultimate kick! Feel the energizing power of 320mg of caffeine per liter!",
    point1: "*Caffeine helps to improve alertness and concentration.",
    point2: "** VOLT contains a total of 32mg/100ml of natural caffeine from Robusta and Arabica coffee beans for a flavour that kicks.",
    point3: "***This makes VOLT about 3x as strong as normal cola on the market. This means that one can of VOLT 0.5 litre contains about the same amount of caffeine (160mg) as a mug/office cup of coffee.",
    caution: "No more than 400mg of caffeine should be consumed per day from all sources combined.",
    explore: "Explore Products",
    impressum_name: "Imprint",
    dataprivacy_name: "Data protection",
    impressum: `
    Imprint
    Service provider, responsible for the content:
    
    KDM Marketing- und Vertriebs GmbH - VOLT Germany
    Kirchweg 74a 50858 Cologne
    Telephone: 0221 - 94 98 70 98
    Fax: 0221 - 94 98 708
    
    E-mail: info@voltcola.de
    Internet: www.voltcola.de
    
    Authorised managing director:
    Mr Klaus Dörrenhaus
    
    Register court: Local court Cologne
    Register number: HRB 26825
    Sales tax identification number according to §27 a sales tax law: DE 811947031
    
    Responsible for content according to § 6 MDStV:
    Klaus Dörrenhaus
    
    technical support, design, technology, questions?
    
    info@voltcola.de
    `,
    datenschutz: `
    Data protection
    The controller within the meaning of data protection laws, in particular the EU General Data Protection Regulation (GDPR), is
    
    KDM Marketing- und Vertriebs GmbH
    
    Kirchweg 74a 50858 Cologne
    
    Telephone: 0221 - 94 98 70 98
    
    Fax: 0221 - 94 98 708
    
    E-mail: info@voltcola.de
    
    Internet: www.voltcola.de
    
    Authorised managing director:
    
    Mr Klaus Dörrenhaus
    
    Register court: Local court Cologne
    
    Register number: HRB 26825
    
    Sales tax identification number according to §27 a sales tax law: DE 811947031
    
    Responsible for content according to § 6 MDStV:
    
    Klaus Dörrenhaus
    
    Your rights as a data subject
    You can exercise the following rights at any time using the contact details provided for our data protection officer:
    
    Information about your data stored by us and its processing,
    Correction of incorrect personal data,
    Deletion of your data stored by us,
    Restriction of data processing if we are not yet allowed to delete your data due to legal obligations,
    objection to the processing of your data by us and
    data portability if you have consented to data processing or have concluded a contract with us.
    If you have given us your consent, you can revoke it at any time with effect for the future.
    
    You can contact the supervisory authority responsible for you at any time with a complaint. Your competent supervisory authority depends on the federal state of your place of residence, your work or the alleged infringement. A list of supervisory authorities (for the non-public sector) with addresses can be found at: https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
    
    Purposes of data processing by the controller and third parties
    We process your personal data only for the purposes stated in this privacy policy. Your personal data will not be transferred to third parties for purposes other than those stated. We only pass on your personal data to third parties if
    
    You have given your express consent to this,
    the processing is necessary for the fulfilment of a contract with you
    the processing is necessary to fulfil a legal obligation
    the processing is necessary to safeguard legitimate interests and there is no reason to assume that you have an overriding interest worthy of protection in your data not being passed on.
    
    Cookies
    Like many other websites, we also use so-called "cookies". Cookies are small text files that are transferred from a website server to your hard drive. This automatically provides us with certain data such as IP address, browser used, operating system and your connection to the Internet.
    
    Cookies cannot be used to start programmes or transfer viruses to a computer. Using the information contained in cookies, we can make navigation easier for you and enable our web pages to be displayed correctly.
    
    Under no circumstances will the data we collect be passed on to third parties or linked to personal data without your consent.
    
    Of course, you can also view our website without cookies. Internet browsers are regularly set to accept cookies. In general, you can deactivate the use of cookies at any time via your browser settings. Please use the help functions of your Internet browser to find out how to change these settings. Please note that individual functions of our website may not work if you have deactivated the use of cookies.
    
    Registration on our website
    When registering to use our personalised services, some personal data is collected, such as name, address, contact and communication data such as telephone number and email address. If you are registered with us, you can access content and services that we only offer to registered users. Registered users also have the option of changing or deleting the data provided during registration at any time. Of course, we will also provide you with information about the personal data we have stored about you at any time. We will also be happy to correct or delete this data at your request, provided there are no statutory retention obligations to the contrary. To contact us in this regard, please use the contact details provided at the end of this privacy policy.
    
    Provision of chargeable services
    In order to provide chargeable services, we request additional data, such as payment details, in order to fulfil your order. We store this data in our systems until the statutory retention periods have expired.
    
    SSL encryption
    To protect the security of your data during transmission, we use state-of-the-art encryption methods (e.g. SSL) via HTTPS.
    
    Comment function
    When users leave comments on our website, the time of their creation and the user name previously selected by the website visitor are stored in addition to this information. This is for our security, as we can be prosecuted for illegal content on our website, even if it was created by users.
    
    Contact form
    If you contact us with questions of any kind by e-mail or contact form, you give us your voluntary consent for the purpose of contacting us. A valid e-mail address is required for this purpose. This is used to allocate the enquiry and subsequently answer it. The provision of further data is optional. The information you provide will be stored for the purpose of processing the enquiry and for possible follow-up questions. After your enquiry has been dealt with, your personal data will be automatically deleted.
    
    Use of Google Maps
    This website uses Google Maps API to visualise geographical information. When Google Maps is used, Google also collects, processes and utilises data about the use of the map functions by visitors. You can find more information about data processing by Google in the Google data protection information. You can also change your personal data protection settings there in the data protection centre.
    
    Detailed instructions on managing your own data in connection with Google products can be found here.
    
    Embedded YouTube videos
    We embed YouTube videos on some of our websites. The operator of the corresponding plugins is YouTube, LLC, 901 Cherry Ave, San Bruno, CA 94066, USA. When you visit a page with the YouTube plugin, a connection to YouTube servers is established. YouTube is informed which pages you visit. If you are logged into your YouTube account, YouTube can assign your surfing behaviour to you personally. You can prevent this by logging out of your YouTube account beforehand.
    
    When a YouTube video is started, the provider uses cookies that collect information about user behaviour.
    
    If you have deactivated the storage of cookies for the Google Ad programme, you will not have to expect any such cookies when watching YouTube videos. However, YouTube also stores non-personalised usage information in other cookies. If you wish to prevent this, you must block the storage of cookies in your browser.
    
    Further information on data protection at "Youtube" can be found in the provider's privacy policy at: https://www.google.de/intl/de/policies/privacy/
    
    Changes to our privacy policy
    We reserve the right to amend this privacy policy so that it always complies with current legal requirements or to implement changes to our services in the privacy policy, e.g. when introducing new services. The new privacy policy will then apply to your next visit.
    
    Questions for the data protection officer
    If you have any questions about data protection, please send us an email or contact the person responsible for data protection in our organisation directly:
    
    The data protection declaration was created with the data protection declaration generator of activeMind AG.`
  },
  Deutsch: {
    intro: "Volt Power Cola liefert Dir den ultimativen Kick! Spüre wie 320mg Koffein pro Liter dich durchdringen und beleben!",
    point1: "*Koffein hilft die Aufmerksamkeit und Konzentration zu verbessern.",
    point2: "** VOLT enthält in total 32mg/100ml natürliches Koffein aus Robusta und Arabica Kaffeebohnen für einen Geschmack, der kickt.",
    point3: "***Damit ist VOLT in etwa 3x so stark im Vergleich zu marktüblicher, normaler Cola. Somit enthält eine Dose VOLT 0,5L in etwa dieselbe Menge Koffein (160mg) wie ein Becher/eine Bürotasse Kaffee.",
    caution: "Pro Tag sollten aus allen Quellen zusammen nicht mehr als 400mg Koffein aufgenommen werden.",
    explore: "Produkte entdecken",
    impressum_name: "Impressum",
    dataprivacy_name: "Datenschutz",
    impressum: `
    Impressum
    Dienstanbieter, verantwortlich für den Inhalt:
    
    KDM Marketing- und Vertriebs GmbH – VOLT Germany
    Kirchweg 74a 50858 Köln
    Telefon: 0221 – 94 98 70 98
    Telefax: 0221 – 94 98 708
    
    E-Mail: info@voltcola.de
    Internet: www.voltcola.de
    
    Vertretungsberechtigter Geschäftsführer:
    Herrn Klaus Dörrenhaus
    
    Registergericht: Amtsgericht Köln
    Registernummer: HRB 26825
    Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE 811947031
    
    Inhaltlich Verantwortlicher gemäß § 6 MDStV:
    Klaus Dörrenhaus
    
    technische Betreuung, Design, Technik, Fragen?
    
    info@voltcola.de
    `,
    datenschutz: `
    Datenschutz

    Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:
    KDM Marketing- und Vertriebs GmbH
    
    Kirchweg 74a 50858 Köln
    
    Telefon: 0221 – 94 98 70 98
    
    Telefax: 0221 – 94 98 708
    E-Mail: info@voltcola.de
    
    Internet: www.voltcola.de
    Vertretungsberechtigter Geschäftsführer:
    
    Herrn Klaus Dörrenhaus
    Registergericht: Amtsgericht Köln
    
    Registernummer: HRB 26825
    
    Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE 811947031
    Inhaltlich Verantwortlicher gemäß § 6 MDStV:
    
    Klaus Dörrenhaus
    
    Ihre Betroffenenrechte
    Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:
        • Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung,
        • Berichtigung unrichtiger personenbezogener Daten,
        • Löschung Ihrer bei uns gespeicherten Daten,
        • Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen,
        • Widerspruch gegen die Verarbeitung Ihrer Daten bei uns und
        • Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben.
    Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.
    Sie können sich jederzeit mit einer Beschwerde an die für Sie zuständige Aufsichtsbehörde wenden. Ihre zuständige Aufsichtsbehörde richtet sich nach dem Bundesland Ihres Wohnsitzes, Ihrer Arbeit oder der mutmaßlichen Verletzung. Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter: https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
    
    Zwecke der Datenverarbeitung durch die verantwortliche Stelle und Dritte
    Wir verarbeiten Ihre personenbezogenen Daten nur zu den in dieser Datenschutzerklärung genannten Zwecken. Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den genannten Zwecken findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
        • Sie Ihre ausdrückliche Einwilligung dazu erteilt haben,
        • die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen erforderlich ist,
        • die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist,
    die Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben.
    
    Cookies
    Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“. Cookies sind kleine Textdateien, die von einem Websiteserver auf Ihre Festplatte übertragen werden. Hierdurch erhalten wir automatisch bestimmte Daten wie z. B. IP-Adresse, verwendeter Browser, Betriebssystem und Ihre Verbindung zum Internet.
    Cookies können nicht verwendet werden, um Programme zu starten oder Viren auf einen Computer zu übertragen. Anhand der in Cookies enthaltenen Informationen können wir Ihnen die Navigation erleichtern und die korrekte Anzeige unserer Webseiten ermöglichen.
    In keinem Fall werden die von uns erfassten Daten an Dritte weitergegeben oder ohne Ihre Einwilligung eine Verknüpfung mit personenbezogenen Daten hergestellt.
    Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies betrachten. Internet-Browser sind regelmäßig so eingestellt, dass sie Cookies akzeptieren. Im Allgemeinen können Sie die Verwendung von Cookies jederzeit über die Einstellungen Ihres Browsers deaktivieren. Bitte verwenden Sie die Hilfefunktionen Ihres Internetbrowsers, um zu erfahren, wie Sie diese Einstellungen ändern können. Bitte beachten Sie, dass einzelne Funktionen unserer Website möglicherweise nicht funktionieren, wenn Sie die Verwendung von Cookies deaktiviert haben.
    
    Registrierung auf unserer Webseite
    Bei der Registrierung für die Nutzung unserer personalisierten Leistungen werden einige personenbezogene Daten erhoben, wie Name, Anschrift, Kontakt- und Kommunikationsdaten wie Telefonnummer und E-Mail-Adresse. Sind Sie bei uns registriert, können Sie auf Inhalte und Leistungen zugreifen, die wir nur registrierten Nutzern anbieten. Angemeldete Nutzer haben zudem die Möglichkeit, bei Bedarf die bei Registrierung angegebenen Daten jederzeit zu ändern oder zu löschen. Selbstverständlich erteilen wir Ihnen darüber hinaus jederzeit Auskunft über die von uns über Sie gespeicherten personenbezogenen Daten. Gerne berichtigen bzw. löschen wir diese auch auf Ihren Wunsch, soweit keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Zur Kontaktaufnahme in diesem Zusammenhang nutzen Sie bitte die am Ende dieser Datenschutzerklärung angegebenen Kontaktdaten.
    
    Erbringung kostenpflichtiger Leistungen
    Zur Erbringung kostenpflichtiger Leistungen werden von uns zusätzliche Daten erfragt, wie z.B. Zahlungsangaben, um Ihre Bestellung ausführen zu können. Wir speichern diese Daten in unseren Systemen bis die gesetzlichen Aufbewahrungsfristen abgelaufen sind.
    
    SSL-Verschlüsselung
    Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
    
    Kommentarfunktion
    Wenn Nutzer Kommentare auf unserer Website hinterlassen, werden neben diesen Angaben auch der Zeitpunkt ihrer Erstellung und der zuvor durch den Websitebesucher gewählte Nutzername gespeichert. Dies dient unserer Sicherheit, da wir für widerrechtliche Inhalte auf unserer Webseite belangt werden können, auch wenn diese durch Benutzer erstellt wurden.
    
    Kontaktformular
    Treten Sie bzgl. Fragen jeglicher Art per E-Mail oder Kontaktformular mit uns in Kontakt, erteilen Sie uns zum Zwecke der Kontaktaufnahme Ihre freiwillige Einwilligung. Hierfür ist die Angabe einer validen E-Mail-Adresse erforderlich. Diese dient der Zuordnung der Anfrage und der anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist optional. Die von Ihnen gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert. Nach Erledigung der von Ihnen gestellten Anfrage werden personenbezogene Daten automatisch gelöscht.
    
    Verwendung von Google Maps
    Diese Webseite verwendet Google Maps API, um geographische Informationen visuell darzustellen. Bei der Nutzung von Google Maps werden von Google auch Daten über die Nutzung der Kartenfunktionen durch Besucher erhoben, verarbeitet und genutzt. Nähere Informationen über die Datenverarbeitung durch Google können Sie den Google-Datenschutzhinweisen entnehmen. Dort können Sie im Datenschutzcenter auch Ihre persönlichen Datenschutz-Einstellungen verändern.
    Ausführliche Anleitungen zur Verwaltung der eigenen Daten im Zusammenhang mit Google-Produkten finden Sie hier.
    
    Eingebettete YouTube-Videos
    Auf einigen unserer Webseiten betten wir Youtube-Videos ein. Betreiber der entsprechenden Plugins ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie eine Seite mit dem YouTube-Plugin besuchen, wird eine Verbindung zu Servern von Youtube hergestellt. Dabei wird Youtube mitgeteilt, welche Seiten Sie besuchen. Wenn Sie in Ihrem Youtube-Account eingeloggt sind, kann Youtube Ihr Surfverhalten Ihnen persönlich zuzuordnen. Dies verhindern Sie, indem Sie sich vorher aus Ihrem Youtube-Account ausloggen.
    Wird ein Youtube-Video gestartet, setzt der Anbieter Cookies ein, die Hinweise über das Nutzerverhalten sammeln.
    Wer das Speichern von Cookies für das Google-Ad-Programm deaktiviert hat, wird auch beim Anschauen von Youtube-Videos mit keinen solchen Cookies rechnen müssen. Youtube legt aber auch in anderen Cookies nicht-personenbezogene Nutzungsinformationen ab. Möchten Sie dies verhindern, so müssen Sie das Speichern von Cookies im Browser blockieren.
    Weitere Informationen zum Datenschutz bei „Youtube“ finden Sie in der Datenschutzerklärung des Anbieters unter: https://www.google.de/intl/de/policies/privacy/
    
    Änderung unserer Datenschutzbestimmungen
    Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.
    
    Fragen an den Datenschutzbeauftragten
    Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation:
    Die Datenschutzerklärung wurde mit dem Datenschutzerklärungs-Generator der activeMind AG erstellt.
    `
  },
  العربية: {
    intro: "!تمنحك كولا فولت باور كولا أفضل ركلة! اشعر كيف يتخلل 320 ملغ من الكافيين في اللتر الواحد وينشطك ",
    point1: ".يساعد الكافيين على تحسين اليقظة والتركيز*",
    point2: ".يحتوي فولت على ما مجموعه 32 ملجم/100 مل من الكافيين الطبيعي من حبوب قهوة روبوستا وأرابيكا لنكهة رائعة** ",
    point3: ".هذا يجعل من فولت أقوى من الكولا العادية في السوق بحوالي 3 أضعاف الكولا العادية. وهذا يعني أن علبة واحدة من VOLT سعة 0.5 لتر تحتوي على نفس كمية الكافيين (160 ملغ) التي يحتوي عليها كوب/كوب من القهوة***",
    caution: "يجب عدم استهلاك أكثر من 400 ملغ من الكافيين يومياً من جميع المصادر مجتمعة.",
    explore: "استكشاف المنتجات",
    impressum_name: "Imprint",
    dataprivacy_name: "خصوصية البيانات",
    impressum: ``,
    datenschutz: ":حماية البيانات في منظمتنا مباشرة"
  },
  Bulgarsk: {
    intro: "Volt Power Cola ви дава върховен удар! Почувствайте как 320 mg кофеин на литър ви прониква и ободрява!",
    point1: "*Кофеинът помага за подобряване на бдителността и концентрацията.",
    point2: "** VOLT съдържа общо 32mg/100ml естествен кофеин от кафе на зърна Робуста и Арабика за вкус, който разтърсва.",
    point3: "***Това прави VOLT около 3 пъти по-силен от обикновената кола на пазара. Това означава, че една кутийка VOLT 0,5 л съдържа приблизително същото количество кофеин (160 мг) като една чаша/кафе в офиса.",
    caution: "На ден не трябва да се консумират повече от 400 mg кофеин от всички източници взети заедно.",
    explore: "Разгледайте продуктите",
    impressum_name: "Imprint",
    dataprivacy_name: "Защита на данните",
    impressum: ``,
    datenschutz: "Защита на данните в нашата организация директно:"
  },
  简体中文: {
    intro: "伏特动力可乐为您带来终极刺激！感受每升 320 毫克的咖啡因如何渗透并振奋您的精神！",
    point1: "*咖啡因有助于提高警觉性和注意力。",
    point2: "** VOLT 从罗布斯塔咖啡豆和阿拉比卡咖啡豆中萃取出 32 毫克/100 毫升的天然咖啡因，口感劲爽。",
    point3: "***这使得 VOLT 的浓度是市场上普通可乐的 3 倍。这意味着一罐 0.5 升 VOLT 所含的咖啡因（160 毫克）与一杯咖啡（办公室用）的咖啡因含量相当。",
    caution: "每天从所有来源摄入的咖啡因总和不应超过 400 毫克。",
    explore: "探索产品",
    impressum_name: "Imprint",
    dataprivacy_name: "数据隐私",
    impressum: ``,
    datenschutz: "我们组织中的数据保护直接："
  },
  DANSK: {
    intro: "Volt Power Cola giver dig det ultimative kick! Mærk, hvordan 320 mg koffein pr. liter gennemsyrer og opfrisker dig!",
    point1: "*Koffein hjælper med at forbedre årvågenhed og koncentration.",
    point2: "** VOLT indeholder i alt 32 mg/100 ml naturlig koffein fra Robusta- og Arabica-kaffebønner for en smag, der sparker.",
    point3: "***Det gør VOLT ca. 3 gange så stærk som normal cola på markedet. Det betyder, at en dåse VOLT 0,5 liter indeholder ca. samme mængde koffein (160 mg) som en kop kaffe på kontoret.",
    caution: "Man bør ikke indtage mere end 400 mg koffein om dagen fra alle kilder tilsammen.",
    explore: "Udforsk produkter",
    impressum_name: "Imprint",
    dataprivacy_name: "Databeskyttelse",
    impressum: ``,
    datenschutz: "Databeskyttelse i vores organisation direkte:"
  },
  ESTNISH: {
    intro: "Volt Power Cola annab sulle ülima kick'i! Tunne, kuidas 320mg kofeiini liitri kohta läbib ja ergutab sind!",
    point1: "*Kofeiin aitab parandada tähelepanelikkust ja keskendumisvõimet.",
    point2: "** VOLT sisaldab kokku 32mg/100ml looduslikku kofeiini, mis on saadud Robusta ja Arabica kohviubadest, et saavutada kick'i maitse.",
    point3: "***See teeb VOLTi umbes 3x nii tugevaks kui tavaline koola turul. See tähendab, et üks 0,5-liitrine purk VOLTi sisaldab umbes sama palju kofeiini (160mg) kui üks tass kohvi.",
    caution: "Kõigist allikatest kokku ei tohiks päevas tarbida rohkem kui 400 mg kofeiini.",
    explore: "Avasta tooteid",
    impressum_name: "Imprint",
    dataprivacy_name: "Andmekaitse",
    impressum: ``,
    datenschutz: "Andmekaitse meie organisatsioonis otse:"
  },
  FINNISH: {
    intro: "Volt Power Cola antaa sinulle täydellisen potkun! Tunne, kuinka 320 mg kofeiinia litrassa läpäisee ja virkistää sinut!",
    point1: "*Kofeiini auttaa parantamaan vireystilaa ja keskittymiskykyä.",
    point2: "** VOLT sisältää yhteensä 32mg/100ml luonnollista kofeiinia Robusta- ja Arabica-kahvipavuista, mikä antaa potkua makuun.",
    point3: "***Tämä tekee VOLTista noin 3x niin vahvan kuin tavallinen markkinoilla oleva cola. Tämä tarkoittaa, että yksi tölkki VOLT 0,5 litraa sisältää noin saman määrän kofeiinia (160mg) kuin mukillinen/toimistokupillinen kahvia.",
    caution: "Kofeiinia tulisi nauttia enintään 400 mg päivässä kaikista lähteistä yhteensä.",
    explore: "Tutustu tuotteisiin",
    impressum_name: "Imprint",
    dataprivacy_name: "Tietosuoja",
    impressum: ``,
    datenschutz: "Tietosuoja organisaatiossamme suoraan:"
  },
  FRANÇAIS: {
    intro: "Volt Power Cola te donne le coup de fouet ultime ! Sens comme 320mg de caféine par litre te pénètrent et te revigorent !",
    point1: "*La caféine aide à améliorer l'attention et la concentration.",
    point2: "** VOLT contient au total 32mg/100ml de caféine naturelle issue de grains de café robusta et arabica pour un goût qui donne la pêche.",
    point3: "***VOLT est donc environ 3 fois plus fort que les colas ordinaires du marché. Ainsi, une canette de VOLT 0,5L contient à peu près la même quantité de caféine (160 mg) qu'un gobelet/une tasse de café.",
    caution: "Toutes sources confondues, il ne faut pas consommer plus de 400 mg de caféine par jour.",
    explore: "Explorer les produits",
    impressum_name: "Mentions légales",
    dataprivacy_name: "Confidentialité des données",
    impressum: `
    Mentions légales
    Fournisseur de services, responsable du contenu :
    
    KDM Marketing- und Vertriebs GmbH - VOLT Germany
    Kirchweg 74a 50858 Cologne
    Téléphone : 0221 - 94 98 70 98
    Télécopie : 0221 - 94 98 708
    
    Courrier électronique : info@voltcola.de
    Internet : www.voltcola.de
    
    Gérant autorisé à représenter la société :
    Monsieur Klaus Dörrenhaus
    
    Tribunal d'enregistrement : Tribunal d'instance de Cologne
    Numéro d'enregistrement : HRB 26825
    Numéro d'identification de la taxe sur le chiffre d'affaires conformément au §27 a de la loi sur la TVA : DE 811947031
    
    Responsable du contenu conformément au § 6 MDStV :
    Klaus Dörrenhaus
    
    assistance technique, design, technique, questions ?
    
    info@voltcola.de
    `,
    datenschutz: "Protection des données dans notre organisation directement:"
  },
  ΕΛΛΗΝΙΚΑ: {
    intro: "Η Volt Power Cola σας δίνει το απόλυτο λάκτισμα! Νιώστε πώς τα 320mg καφεΐνης ανά λίτρο σας διαπερνούν και σας αναζωογονούν!",
    point1: "*Η καφεΐνη συμβάλλει στη βελτίωση της εγρήγορσης και της συγκέντρωσης.",
    point2: "**Η VOLT περιέχει συνολικά 32mg/100ml φυσικής καφεΐνης από κόκκους καφέ Robusta και Arabica για μια γεύση που κλωτσάει.",
    point3: "***Αυτό καθιστά το VOLT περίπου 3 φορές πιο δυνατό από την κανονική κόλα της αγοράς. Αυτό σημαίνει ότι ένα κουτάκι VOLT 0,5 λίτρου περιέχει περίπου την ίδια ποσότητα καφεΐνης (160mg) με μια κούπα/φλιτζάνι καφέ γραφείου.",
    caution: "Δεν πρέπει να καταναλώνονται περισσότερα από 400mg καφεΐνης την ημέρα από όλες τις πηγές μαζί.",
    explore: "Εξερευνήστε τα προϊόντα",
    impressum_name: "Imprint",
    dataprivacy_name: "Προστασία δεδομένων",
    impressum: ``,
    datenschutz: "Προστασία δεδομένων στην οργάνωσή μας άμεσα:"
  },
  INDONESIA: {
    intro: "Volt Power Cola memberi Anda tendangan terbaik! Rasakan bagaimana 320mg kafein per liter meresap dan menyegarkan Anda!",
    point1: "*Kafein membantu meningkatkan kewaspadaan dan konsentrasi.",
    point2: "** VOLT mengandung total 32mg/100ml kafein alami dari biji kopi Robusta dan Arabika untuk rasa yang lebih nikmat.",
    point3: "*** Hal ini membuat VOLT sekitar 3x lebih kuat dari cola biasa yang ada di pasaran. Ini berarti satu kaleng VOLT 0,5 liter mengandung jumlah kafein yang sama (160mg) dengan secangkir kopi di kantor.",
    caution: "Tidak lebih dari 400mg kafein yang harus dikonsumsi per hari dari semua sumber gabungan.",
    explore: "Jelajahi Produk",
    impressum_name: "Imprint",
    dataprivacy_name: "Privasi Data",
    impressum: ``,
    datenschutz: "Perlindungan data dalam organisasi kami langsung:"
  },
  ITALIANO: {
    intro: "Volt Power Cola vi dà il massimo della carica! Sentite come 320 mg di caffeina per litro vi pervadono e vi rinvigoriscono!",
    point1: "*La caffeina aiuta a migliorare la vigilanza e la concentrazione.",
    point2: "** VOLT contiene un totale di 32 mg/100 ml di caffeina naturale ricavata dai chicchi di caffè Robusta e Arabica, per un sapore che spacca.",
    point3: "***Questo rende VOLT tre volte più forte della normale cola presente sul mercato. Ciò significa che una lattina di VOLT da 0,5 litri contiene circa la stessa quantità di caffeina (160 mg) di una tazza di caffè.",
    caution: "Non si dovrebbero consumare più di 400 mg di caffeina al giorno da tutte le fonti messe insieme.",
    explore: "Esplora i Prodotti",
    impressum_name: "Imprint",
    dataprivacy_name: "Privacy dei dati",
    impressum: ``,
    datenschutz: "Protezione dei dati nella nostra organizzazione direttamente:"
  },
  日本語: {
    intro: "ヴォルトパワーコーラは、あなたに究極のキックを与えます！1リットルあたり320mgのカフェインが、あなたを元気にしてくれます！",
    point1: "*カフェインが覚醒と集中力を高めます。",
    point2: "** VOLTには、ロブスタ種とアラビカ種のコーヒー豆から抽出した天然カフェインが合計32mg/100ml含まれています。",
    point3: "***このため、VOLTは市販されている通常のコーラの約3倍の強さがある。つまり、VOLT 0.5リットル缶1缶には、マグカップ1杯分のコーヒーと同量のカフェイン（160mg）が含まれていることになる。",
    caution: "カフェインは、すべての摂取源を合わせて1日400mgを超えて摂取してはならない。",
    explore: "製品を探索する",
    impressum_name: "Imprint",
    dataprivacy_name: "データプライバシー",
    impressum: ``,
    datenschutz: "私たちの組織におけるデータ保護:"
  },
  LETTISCH: {
    intro: "Volt Power Cola sniedz jums maksimālu enerģijas uzmundrinājumu! Sajūtiet, kā 320 mg kofeīna litrā jūs caurstrāvo un uzmundrina!",
    point1: "* Kofeīns palīdz uzlabot modrību un koncentrēšanās spējas.",
    point2: "** VOLT satur 32 mg/100 ml dabīgā kofeīna, kas iegūts no Robusta un Arabica kafijas pupiņām, lai garša būtu aizraujoša.",
    point3: "***Tas VOLT padara apmēram 3 reizes spēcīgāku nekā parastā kola tirgū. Tas nozīmē, ka vienā VOLT 0,5 litru kārbā ir apmēram tikpat daudz kofeīna (160 mg), cik krūzī vai biroja kafijas tasītē.",
    caution: "No visiem avotiem kopā dienā nevajadzētu lietot vairāk par 400 mg kofeīna.",
    explore: "Izpētiet produktus",
    impressum_name: "Imprint",
    dataprivacy_name: "Datu aizsardzība",
    impressum: ``,
    datenschutz: "Datu aizsardzība mūsu organizācijā tieši:"
  },
  LIETUVIŲ: {
    intro: "Volt Power Cola suteikia puikų užtaisą! Pajuskite, kaip 320 mg kofeino viename litre persmelkia ir suteikia žvalumo!",
    point1: "*Kofeinas padeda pagerinti budrumą ir koncentraciją.",
    point2: "** VOLT sudėtyje yra 32 mg/100 ml natūralaus kofeino iš Robusta ir Arabica kavos pupelių, kad skonis būtų svaiginantis.",
    point3: "***Dėl to VOLT yra maždaug 3 kartus stipresnė už įprastą rinkoje esančią kolą. Tai reiškia, kad vienoje VOLT 0,5 l skardinėje yra maždaug toks pat kofeino kiekis (160 mg) kaip puodelyje ar biuro kavos puodelyje.",
    caution: "Per dieną iš visų šaltinių kartu sudėjus reikėtų suvartoti ne daugiau kaip 400 mg kofeino.",
    explore: "Naršyti produktus",
    impressum_name: "Imprint",
    dataprivacy_name: "Duomenų apsauga",
    impressum: ``,
    datenschutz: "Duomenų apsauga mūsų organizacijoje tiesiogiai:"
  },
  NEDERLAND: {
    intro: "Volt Power Cola geeft je de ultieme kick! Voel hoe 320mg cafeïne per liter je doordringt en verkwikt!",
    point1: "*Cafeïne helpt de alertheid en concentratie te verbeteren.",
    point2: "** VOLT bevat in totaal 32mg/100ml natuurlijke cafeïne uit Robusta en Arabica koffiebonen voor een kickvolle smaak.",
    point3: "***Dit maakt VOLT ongeveer 3x zo sterk als normale cola op de markt. Dit betekent dat één blik VOLT 0,5 liter ongeveer evenveel cafeïne (160mg) bevat als een mok/kantoorkopje koffie.",
    caution: "Per dag mag uit alle bronnen samen niet meer dan 400 mg cafeïne worden geconsumeerd.",
    explore: "Ontdek producten",
    impressum_name: "Imprint",
    dataprivacy_name: "Gegevensprivacy",
    impressum: ``,
    datenschutz: "Gegevensbescherming in onze organisatie direct:"
  },
  Bokmål: {
    intro: "Volt Power Cola gir deg det ultimate kicket! Kjenn hvordan 320 mg koffein per liter gjennomsyrer og styrker deg!",
    point1: "*Koffein bidrar til å forbedre årvåkenhet og konsentrasjon.",
    point2: "** VOLT inneholder totalt 32 mg/100 ml naturlig koffein fra Robusta- og Arabica-kaffebønner for en smak som gir et kick.",
    point3: "***Dette gjør VOLT omtrent 3 ganger så sterk som vanlig cola på markedet. Det betyr at en boks VOLT 0,5 liter inneholder omtrent like mye koffein (160 mg) som en kopp kaffe på kontoret.",
    caution: "Det bør ikke inntas mer enn 400 mg koffein per dag fra alle kilder til sammen.",
    explore: "Utforsk produkter",
    impressum_name: "Imprint",
    dataprivacy_name: "Personvern",
    impressum: ``,
    datenschutz: "Personvern i vår organisasjon direkte:"
  },
  POLSKI: {
    intro: "Volt Power Cola daje niesamowitego kopa! Poczuj, jak 320 mg kofeiny na litr przenika Cię i pobudza!",
    point1: "*Kofeina pomaga poprawić czujność i koncentrację.",
    point2: "** VOLT zawiera łącznie 32mg/100ml naturalnej kofeiny z ziaren kawy Robusta i Arabica dla smaku, który kopie.",
    point3: "***To sprawia, że VOLT jest około 3x mocniejszy niż zwykła cola dostępna na rynku. Oznacza to, że jedna puszka VOLT o pojemności 0,5 litra zawiera mniej więcej taką samą ilość kofeiny (160 mg) jak kubek/filiżanka kawy.",
    caution: "Nie należy spożywać więcej niż 400 mg kofeiny dziennie ze wszystkich źródeł łącznie.",
    explore: "Odkrywaj produkty",
    impressum_name: "Imprint",
    dataprivacy_name: "Prywatność danych",
    impressum: ``,
    datenschutz: "Ochrona danych w naszej organizacji bezpośrednio:"
  },
  PORTUGUÊS: {
    intro: "Volt Power Cola dá-lhe o derradeiro pontapé! Sinta como 320mg de cafeína por litro o permeiam e revigoram!",
    point1: "*A cafeína ajuda a melhorar o estado de alerta e a concentração.",
    point2: "** A VOLT contém um total de 32 mg/100 ml de cafeína natural proveniente de grãos de café Robusta e Arábica, para um sabor que dá um pontapé de saída.",
    point3: "***Isto torna a VOLT cerca de 3x mais forte do que a cola normal existente no mercado. Isto significa que uma lata de VOLT de 0,5 litros contém aproximadamente a mesma quantidade de cafeína (160 mg) que uma chávena de café.",
    caution: "Não devem ser consumidos mais de 400 mg de cafeína por dia, de todas as fontes combinadas.",
    explore: "Explorar produtos",
    impressum_name: "Imprint",
    dataprivacy_name: "Privacidade de dados",
    impressum: ``,
    datenschutz: "Proteção de dados na nossa organização diretamente:"
  },
  PORTUGUÊS_BR: {
    intro: "A Volt Power Cola lhe dá o maior impulso! Sinta como 320 mg de cafeína por litro permeiam e revigoram você!",
    point1: "*A cafeína ajuda a melhorar o estado de alerta e a concentração.",
    point2: "** A VOLT contém um total de 32 mg/100 ml de cafeína natural proveniente de grãos de café Robusta e Arábica para um sabor que dá o pontapé inicial.",
    point3: "***Isso torna a VOLT cerca de 3 vezes mais forte do que a cola normal do mercado. Isso significa que uma lata de VOLT de 0,5 litro contém aproximadamente a mesma quantidade de cafeína (160 mg) que uma xícara de café.",
    caution: "Não se deve consumir mais do que 400 mg de cafeína por dia de todas as fontes combinadas.",
    explore: "Explorar produtos",
    impressum_name: "Impressum",
    dataprivacy_name: "Privacidade de dados",
    impressum: ``,
    datenschutz: "Proteção de dados na nossa organização diretamente:"
  },
  ROMAN: {
    intro: "Volt Power Cola îți oferă cea mai tare lovitură! Simte cum 320mg de cofeină la litru te pătrunde și te revigorează!",
    point1: "*Cafeina ajută la îmbunătățirea vigilenței și a concentrării.",
    point2: "** VOLT conține un total de 32mg/100ml de cofeină naturală din boabe de cafea Robusta și Arabica pentru o aromă care lovește.",
    point3: "***Aceasta face ca VOLT să fie de aproximativ 3 ori mai puternică decât cola normală de pe piață. Acest lucru înseamnă că o cutie de VOLT de 0,5 litri conține aproximativ aceeași cantitate de cofeină (160mg) ca o cană/ceașcă de cafea de birou.",
    caution: "Nu ar trebui să se consume mai mult de 400mg de cofeină pe zi din toate sursele combinate.",
    explore: "Explorați produsele",
    impressum_name: "Impressum",
    dataprivacy_name: "Confidențialitatea datelor",
    impressum: ``,
    datenschutz: "Protecția datelor în organizația noastră direct:"
  },
  РУССКИЙ: {
    intro: "Вольт Пауэр Кола дает вам максимальный толчок! Почувствуйте, как 320 мг кофеина на литр пронизывают и бодрят вас!",
    point1: "*Кофеин помогает повысить бдительность и концентрацию.",
    point2: "** VOLT содержит в общей сложности 32 мг/100 мл натурального кофеина из кофейных зерен робусты и арабики для потрясающего вкуса.",
    point3: "***Это делает VOLT примерно в 3 раза крепче обычной колы, представленной на рынке. Это означает, что в одной банке VOLT объемом 0,5 литра содержится примерно столько же кофеина (160 мг), сколько в кружке или офисной чашке кофе.",
    caution: "В день следует употреблять не более 400 мг кофеина из всех источников вместе взятых.",
    explore: "Исследовать продукты",
    impressum_name: "Импрессум",
    dataprivacy_name: "Конфиденциальность данных",
    impressum: ``,
    datenschutz: "Защита данных в нашей организации напрямую:"
  },
  SVENSK: {
    intro: "Volt Power Cola ger dig den ultimata kicken! Känn hur 320 mg koffein per liter genomsyrar och piggar upp dig!",
    point1: "*Koffein hjälper till att förbättra vakenhet och koncentration.",
    point2: "** VOLT innehåller totalt 32 mg/100 ml naturligt koffein från Robusta- och Arabica-kaffebönor för en smak som ger en kick.",
    point3: "***Det gör VOLT ungefär tre gånger så stark som vanlig cola på marknaden. Det innebär att en burk VOLT 0,5 liter innehåller ungefär samma mängd koffein (160 mg) som en mugg/kontorskaffe.",
    caution: "Man bör inte konsumera mer än 400 mg koffein per dag från alla källor tillsammans.",
    explore: "Utforska produkter",
    impressum_name: "Impressum",
    dataprivacy_name: "Dataskydd",
    impressum: ``,
    datenschutz: "Dataskydd i vår organisation direkt:"
  },
  SLOVENSKÉ: {
    intro: "Volt Power Cola vás maximálne nakopne! Pocíťte, ako vás 320 mg kofeínu na liter prenikne a povzbudí!",
    point1: "*Kofeín pomáha zlepšovať bdelosť a koncentráciu.",
    point2: "** VOLT obsahuje celkovo 32 mg/100 ml prírodného kofeínu z kávových zŕn Robusta a Arabica pre chuť, ktorá nakopne.",
    point3: "***Vďaka tomu je VOLT približne 3x silnejší ako bežná kola na trhu. To znamená, že jedna plechovka VOLT 0,5 l obsahuje približne rovnaké množstvo kofeínu (160 mg) ako hrnček/kancelárska šálka kávy.",
    caution: "Denne by sa nemalo zo všetkých zdrojov spolu skonzumovať viac ako 400 mg kofeínu.",
    explore: "Preskúmať produkty",
    impressum_name: "Impressum",
    dataprivacy_name: "Ochrana údajov",
    impressum: ``,
    datenschutz: "Ochrana údajov v našej organizácii priamo:"
  },
  SLOWENIC: {
    intro: "Volt Power Cola vam daje vrhunski zagon! Občutite, kako vas 320 mg kofeina na liter preplavi in poživi!",
    point1: "*Kofein pomaga izboljšati budnost in koncentracijo.",
    point2: "** VOLT vsebuje skupno 32 mg/100 ml naravnega kofeina iz kavnih zrn Robusta in Arabica za okus, ki navdušuje.",
    point3: "***Zaradi tega je VOLT približno 3-krat močnejši od običajne kole na trgu. To pomeni, da ena pločevinka VOLT 0,5 l vsebuje približno enako količino kofeina (160 mg) kot skodelica kave.",
    caution: "Iz vseh virov skupaj ne smete zaužiti več kot 400 mg kofeina na dan.",
    explore: "Raziskujte izdelke",
    impressum_name: "Impressum",
    dataprivacy_name: "Varstvo podatkov",
    impressum: ``,
    datenschutz: "Varstvo podatkov v naši organizaciji neposredno:"
  },
  ESPAÑOL: {
    intro: "¡Volt Power Cola te da el subidón definitivo! ¡Siente cómo 320mg de cafeína por litro te impregnan y vigorizan!",
    point1: "*La cafeína ayuda a mejorar el estado de alerta y la concentración.",
    point2: "** VOLT contiene un total de 32mg/100ml de cafeína natural procedente de los granos de café Robusta y Arábica para un sabor que patea.",
    point3: "***Esto hace que VOLT sea 3 veces más fuerte que la cola normal del mercado. Esto significa que una lata de VOLT de 0,5 litros contiene aproximadamente la misma cantidad de cafeína (160 mg) que una taza de café.",
    caution: "No se deben consumir más de 400 mg de cafeína al día de todas las fuentes combinadas.",
    explore: "Explorar productos",
    impressum_name: "Pie de imprenta",
    dataprivacy_name: "Privacidad de datos",
    impressum: `
    Pie de imprenta
    Proveedor de servicios, responsable del contenido:
    
    KDM Marketing- und Vertriebs GmbH - VOLT Alemania
    Kirchweg 74a 50858 Colonia
    Teléfono: 0221 - 94 98 70 98
    Fax: 0221 - 94 98 708
    
    Correo electrónico: info@voltcola.de
    Internet: www.voltcola.de
    
    Director gerente autorizado:
    Sr. Klaus Dörrenhaus
    
    Tribunal de registro: Juzgado municipal de Colonia
    Número de registro: HRB 26825
    Número de identificación fiscal según §27 a de la ley del impuesto sobre la venta: DE 811947031
    
    Responsable del contenido según § 6 MDStV:
    Klaus Dörrenhaus
    
    soporte técnico, diseño, tecnología, ¿preguntas?
    
    info@voltcola.de
    
    
    `,
    datenschutz: "Protección de datos en nuestra organización directamente:"
  },
  ČESKY: {
    intro: "Volt Power Cola vás nakopne! Pocítíte, jak vás 320 mg kofeinu na litr prostoupí a povzbudí!",
    point1: "*Kofein pomáhá zlepšovat bdělost a koncentraci.",
    point2: "** VOLT obsahuje celkem 32 mg/100 ml přírodního kofeinu z kávových zrn Robusta a Arabica pro chuť, která nakopne.",
    point3: "***Díky tomu je VOLT přibližně 3x silnější než běžná kola na trhu. To znamená, že jedna plechovka VOLT 0,5 l obsahuje přibližně stejné množství kofeinu (160 mg) jako hrnek/kancelářský šálek kávy.",
    caution: "Ze všech zdrojů dohromady by se nemělo denně zkonzumovat více než 400 mg kofeinu.",
    explore: "Prozkoumat produkty",
    impressum_name: "Impressum",
    dataprivacy_name: "Ochrana osobních údajů",
    impressum: ``,
    datenschutz: "Ochrana osobních údajů v naší organizaci přímo:"
  },
  TÜRKÇE: {
    intro: "Volt Power Cola size en üst düzeyde enerji verir! Litre başına 320 mg kafeinin sizi nasıl etkilediğini ve canlandırdığını hissedin!",
    point1: "*Kafein uyanıklığı ve konsantrasyonu artırmaya yardımcı olur.",
    point2: "** VOLT, Robusta ve Arabica kahve çekirdeklerinden elde edilen toplam 32mg/100ml doğal kafein içerir.",
    point3: "***Bu da VOLT'u piyasadaki normal koladan yaklaşık 3 kat daha güçlü yapar. Bu, 0,5 litrelik bir kutu VOLT'un bir kupa/ofis fincanı kahve ile yaklaşık aynı miktarda kafein (160 mg) içerdiği anlamına gelir.",
    caution: "Tüm kaynakların toplamından günde 400 mg'dan fazla kafein tüketilmemelidir.",
    explore: "Ürünleri keşfet",
    impressum_name: "İmpressum",
    dataprivacy_name: "Veri Gizliliği",
    impressum: ``,
    datenschutz: "Kuruluşumuzdaki veri koruma doğrudan:"
  },
  УКРАЇНСЬКА: {
    intro: "Volt Power Cola дарує максимальний заряд бадьорості! Відчуйте, як 320 мг кофеїну на літр пронизує та бадьорить вас!",
    point1: "*Кофеїн сприяє підвищенню бадьорості та концентрації уваги.",
    point2: "** VOLT містить 32 мг/100 мл натурального кофеїну з кавових зерен робусти та арабіки, що додає смаку, який бадьорить.",
    point3: "***Це робить VOLT приблизно в 3 рази сильнішим за звичайну колу на ринку. Це означає, що одна банка VOLT об'ємом 0,5 л містить приблизно стільки ж кофеїну (160 мг), скільки горнятко/офісна чашка кави.",
    caution: "Не більше 400 мг кофеїну слід споживати на день з усіх джерел разом узятих.",
    explore: "Досліджуйте продукти",
    impressum_name: "Імпресум",
    dataprivacy_name: "Конфіденційність даних",
    impressum: ``,
    datenschutz: "Захист даних у нашій організації безпосередньо:"
  },
  MAGYARORSZÁG: {
    intro: "A Volt Power Cola adja meg a végső rúgást! Érezd, ahogy a literenkénti 320mg koffein átjár és felpezsdít!",
    point1: "*A koffein segít az éberség és a koncentráció javításában.",
    point2: "** A VOLT összesen 32mg/100ml természetes koffeint tartalmaz a Robusta és Arabica kávébabból, hogy az ízvilága felpörgető legyen.",
    point3: "***Ezáltal a VOLT körülbelül 3x olyan erős, mint a piacon kapható normál kóla. Ez azt jelenti, hogy egy doboz 0,5 literes VOLT körülbelül ugyanannyi koffeint (160mg) tartalmaz, mint egy bögre/csésze kávé.",
    caution: "Naponta legfeljebb 400 mg koffeint szabad fogyasztani minden forrásból együttesen.",
    explore: "Fedezd fel a termékeket",
    impressum_name: "Impressum",
    dataprivacy_name: "Adatvédelem",
    impressum: ``,
    datenschutz: "Adatvédelem a szervezetünkben közvetlenül:"
  }
  
  
};

export default translations;
